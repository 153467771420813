<template>
  <div>你沒有權限到訪此頁面</div>
</template>

<script>
export default {
  name: 'NoPermission',
  data: () => ({}),

  methods: {},
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  padding-top: 30vh;
  width: 100%;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}
</style>
